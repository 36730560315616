<template>
    <div class="timeline">
        <b-overlay :show="isLoading" no-wrap></b-overlay>
        <!-- =========================== -->
        <div class="m-3 text-left">
            <h4 class="border text-white px-4 rounded-pill shadow bg-warning text-dark d-inline">ほかくリスト</h4>
        </div>
        <!-- =========================== -->
        <div v-for="p in dbMapHunts" v-bind:key="p.id" class="m-3">
            <b-card bg-variant="info" text-variant="white" class="text-left" no-body>
                <b-card-header>
                    <b-row>
                        <b-col cols="9" class="text-left font-weight-bold">
                            <b-badge v-show="p.branch_id" variant="danger">{{ branchName(p.branch_id) }}</b-badge>
                            <b-icon icon="person-circle"></b-icon>
                            {{ timelineHeaderLeft(p) }}
                        </b-col>
                        <!--
                        <b-col><b-badge v-show="p.private_id" variant="danger" class="p-2">プライベート投稿</b-badge></b-col>
                        -->
                        <b-col cols="3" class="text-right font-weight-bold">{{ timelineHeaderRight(p) }}</b-col>
                    </b-row>
                </b-card-header>
                <b-card-text class="p-3">
                    <div>■獣種：{{ p.species }} {{ p.gender }} {{ p.age }} {{ p.headcount }}</div>
                    <div>■体長：{{ p.body_length }} <span v-show="p.body_length">cm</span></div>
                    <div>■体高：{{ p.body_height }} <span v-show="p.body_height">cm</span></div>
                    <div>■体重：{{ p.body_weight }} <span v-show="p.body_weight">kg</span></div>
                    <div>■個体番号：{{ p.lot_no }}</div>
                    <div>■罠種：{{ p.trap_type }}</div>
                    <div>■処理：{{ p.disposal }}</div>
                    <div>■地区：{{ p.village }}</div>
                    <div>■メモ：{{ p.title }}</div>
                    <div>■投稿日：{{ p.posted_at }}</div>
                    <b-button block variant="warning" size="sm" @click="setLatLng(p.gps_latitude, p.gps_longitude)" class="mt-3">地図</b-button>
                    <b-img v-show="p.jpgBlob" :src="p.jpgBlob" fluid class="mt-3"></b-img>
                    <b-img v-show="p.jpgBlob2nd" :src="p.jpgBlob2nd" fluid class="mt-3"></b-img>
                    <b-img v-show="p.jpgBlob3rd" :src="p.jpgBlob3rd" fluid class="mt-3"></b-img>
                    <b-img v-show="p.jpgBlob4th" :src="p.jpgBlob4th" fluid class="mt-3"></b-img>
                    <!--
                    <b-button variant="warning" size="sm" class="m-2" v-b-modal.modalImageView @click="getImage(p.jpg_file)" v-show="p.jpg_file">
                        <b-icon icon="card-image"></b-icon>
                    </b-button>
                    -->
                </b-card-text>
            </b-card>
        </div>
        <infinite-loading ref="infiniteLoading" @infinite="infiniteHandler">
            <div slot="no-results">--- No Results ---</div>
            <div slot="spinner">Loading...</div>
            <div slot="no-more">--- No more message ---</div>
            <div slot="no-results">--- No results message ---</div>
        </infinite-loading>

        <div class="m-5 p-5"></div>
        <!-- =========================== -->
        <b-modal id="modalImageView" size="xl" hide-footer>
            <b-aspect :aspect="aspect"><b-img fluid :src="modalImageView.jpgImg"/></b-aspect>
        </b-modal>
        <!-- =========================== -->
        <b-modal id="modalMapView" ref="modalMapView" size="xl" hide-footer>
            <l-map ref="mapView" style="height: 70vh;" :zoom="zoom" :minZoom="minZoom" :center="[center.lat, center.lng]" :options="{ zoomControl: false }">
                <l-tile-layer v-for="tileProvider in tileProviders" :key="tileProvider.name" :name="tileProvider.name" :visible="tileProvider.visible" :url="tileProvider.url" layer-type="base" :attribution="tileProvider.attribution" />
                <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                <l-control-layers position="bottomright"></l-control-layers>
                <l-control-zoom position="bottomleft"></l-control-zoom>
                <l-marker :lat-lng="[center.lat, center.lng]"></l-marker>
            </l-map>
        </b-modal>
        <!-- =========================== -->
    </div>
</template>

<style></style>

<script>
import JWT from "jsonwebtoken";
import InfiniteLoading from "vue-infinite-loading";
//------------------------------------------
// リーフレットJS
//------------------------------------------
import "leaflet/dist/leaflet.css";
import L from "leaflet";
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    markerColor: "orange",
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});
L.Icon.Default.imagePath = "https://unpkg.com/leaflet@1.3.1/dist/images/";
import { LMap, LTileLayer, LControlZoom, LControlLayers, LControlScale, LMarker } from "vue2-leaflet";
//------------------------------------------

export default {
    name: "Timeline",
    components: {
        InfiniteLoading,
        LMap,
        LTileLayer,
        LControlZoom,
        LControlLayers,
        LControlScale,
        LMarker
    },
    //========================================================
    data() {
        return {
            jwt: "",
            myInfo: [],
            dbSite: [],
            dbUser: [],
            dbBranches: [],
            dbMapHunts: [],
            //---------------------
            visOffset: 0,
            //---------------------
            minZoom: 6,
            zoom: 18,
            center: { lat: 35.681, lng: 139.767 },
            //---------------------
            tileProviders: [
                {
                    name: "OpenStreet",
                    visible: false,
                    attribution: '<a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a>',
                    url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                },
                {
                    name: "標準地図",
                    visible: false,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png"
                },
                {
                    name: "衛星写真",
                    visible: true,
                    attribution: '<a target="_blank" href="https://maps.gsi.go.jp/development/ichiran.html">国土地理院</a>',
                    url: "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg"
                }
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}"
                // },
                // {
                //     name: "GoogleMap",
                //     visible: false,
                //     url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                // }
            ],
            //---------------------
            modalImageView: {
                id: 0,
                name: "",
                jpgImg: "",
                date: ""
            },
            aspect: "16:9",
            isLoading: false
        };
    },
    //========================================================
    created: async function() {
        this.jwt = this.$localStorage.get("user-jwt");
        if (!this.jwt) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        this.myInfo = JWT.decode(this.jwt);
        if (this.myInfo.exp < this.$moment().unix()) {
            this.$router.push({ name: "Logout" });
            return false;
        }
        //---------------------------------
        this.isLoading = true;
        this.axiosGetSites();
        this.axiosGetUsers();
        this.axiosGetBranches();
        await new Promise(r => setTimeout(r, 100));
        this.isLoading = false;
        window.scrollTo(0, 0);
        //---------------------------------
    },
    //========================================================
    computed: {},
    //========================================================
    watch: {},
    //========================================================
    mounted: function() {},
    //====================================================
    methods: {
        timelineHeaderLeft: function(p) {
            return p.user_name;
        },
        timelineHeaderRight: function(p) {
            const today = this.$moment().format("YYYY-MM-DD");
            const postday = this.$moment(p.posted_at).format("YYYY-MM-DD");
            const diffDays = this.$moment(today).diff(this.$moment(postday), "days");
            if (diffDays == 0) return "本日";
            return diffDays + "日前";
        },
        //====================================================
        branchName(branch_id) {
            let ret = "";
            for (let i in this.dbBranches) {
                if (branch_id == this.dbBranches[i].id) ret = this.dbBranches[i].name;
            }
            return ret;
        },
        //====================================================
        async axiosGetSites() {
            await this.axios({
                method: "GET",
                url: "/web/api/sites",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) this.dbSite = response.data.json[0];
            });
        },
        //====================================================
        async axiosGetUsers() {
            await this.axios({
                method: "GET",
                url: "/web/api/users",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json[0]) {
                    this.dbUser = response.data.json[0];
                }
            });
        },
        //====================================================
        async axiosGetBranches() {
            await this.axios({
                method: "GET",
                url: "/web/api/branches",
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                if (response.data.json) {
                    this.dbBranches = response.data.json;
                }
            });
        },
        //====================================================
        // async axiosGetMapPoints() {
        //     const pageLimit = 10;
        //     const startRec = this.visOffset * pageLimit;
        //     await this.axios({
        //         method: "GET",
        //         url: "/web/api/map_hunt_points",
        //         params: {
        //             "_order[id]": "desc",
        //             _limit: startRec + "," + pageLimit
        //         },
        //         headers: { Authorization: "Bearer " + this.jwt },
        //         timeout: 30000
        //     }).then(async response => {
        //         //--------------------------
        //         // b-table用に 日付フォーマットを小細工
        //         let tmpJson = response.data.json;
        //         for (let i in tmpJson) {
        //             let posted_at = tmpJson[i].posted_at;
        //             if (posted_at) tmpJson[i].posted_at = this.$moment(posted_at).format("YYYY-MM-DD HH:mm");
        //             //-------------------------------------
        //             // tmpJson[i]["jpgBlob"] = null;
        //             // let jpg_file = tmpJson[i].jpg_file;
        //             // if (jpg_file) tmpJson[i]["jpgBlob"] = await this.getImageBlob(jpg_file);
        //             //-------------------------------------
        //         }
        //         //--------------------------
        //         this.dbMapHunts = this.dbMapHunts.concat(tmpJson);
        //     });
        // },
        //====================================================
        // 無限スクロール
        //====================================================
        infiniteHandler($state) {
            const branch_id = this.myInfo.branch_id;
            const pageLimit = 10;
            const startRec = this.visOffset * pageLimit;
            this.axios({
                method: "GET",
                url: "/web/api/map_hunt_points",
                params: {
                    status: "public",
                    "branch_id[IN]": [0, branch_id].join(","),
                    "_order[id]": "desc",
                    _limit: startRec + "," + pageLimit
                },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(async response => {
                //--------------------------
                // b-table用に 日付フォーマットを小細工
                let tmpJson = response.data.json;
                for (let i in tmpJson) {
                    let posted_at = tmpJson[i].posted_at;
                    if (posted_at) tmpJson[i].posted_at = this.$moment(posted_at).format("YYYY-MM-DD HH:mm");
                    //-------------------------------------
                    tmpJson[i]["jpgBlob"] = null;
                    let jpg_file = tmpJson[i].jpg_file;
                    if (jpg_file) this.getImageBlob(jpg_file, tmpJson[i], "jpgBlob");
                    //-------------------------------------
                    tmpJson[i]["jpgBlob2nd"] = null;
                    let jpg_file_2nd = tmpJson[i].jpg_file_2nd;
                    if (jpg_file_2nd) this.getImageBlob(jpg_file_2nd, tmpJson[i], "jpgBlob2nd");
                    //-------------------------------------
                    tmpJson[i]["jpgBlob3rd"] = null;
                    let jpg_file_3rd = tmpJson[i].jpg_file_3rd;
                    if (jpg_file_3rd) this.getImageBlob(jpg_file_3rd, tmpJson[i], "jpgBlob3rd");
                    //-------------------------------------
                    tmpJson[i]["jpgBlob4th"] = null;
                    let jpg_file_4th = tmpJson[i].jpg_file_4th;
                    if (jpg_file_4th) this.getImageBlob(jpg_file_4th, tmpJson[i], "jpgBlob4th");
                }
                //--------------------------
                this.dbMapHunts = this.dbMapHunts.concat(tmpJson);
                if (response.data.length < pageLimit) {
                    self.console.log("Infinite Load =", this.visOffset);
                    $state.complete();
                } else {
                    this.visOffset += 1;
                    $state.loaded();
                }
            });
            //--------------------------------------------
        },
        //====================================================
        // マップセンター
        //====================================================
        async setLatLng(lat, lng) {
            this.$refs["modalMapView"].show();
            await new Promise(r => setTimeout(r, 100));

            this.center.lat = lat;
            this.center.lng = lng;
            this.zoom = 14;
            const map = this.$refs["mapView"].mapObject;
            map.invalidateSize();
        },
        //====================================================
        async getImageBlob(path, imgObj, key) {
            await this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/image/",
                params: { path: path },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            }).then(response => {
                imgObj[key] = window.URL.createObjectURL(response.data);
            });
        },
        //====================================================
        async getImage(path) {
            this.modalImageView.jpgImg = "";
            //------------------------------------
            await this.axios({
                responseType: "blob",
                method: "GET",
                url: "/web/api/image/",
                params: { path: path },
                headers: { Authorization: "Bearer " + this.jwt },
                timeout: 30000
            })
                .then(response => {
                    this.modalImageView.jpgImg = window.URL.createObjectURL(response.data);
                    this.modalImageView.date = response.headers["x-image-date"];
                })
                .catch(err => {
                    const { status, statusText } = err.response;
                    alert(status + " : " + statusText);
                });
        }
        //====================================================
    }
};
</script>
